<template>
    <div class="client-profile">
        <k-i
                card-title="Заявление на признание физического лица  квалифицированным инвестором"
                :page-title="pageTitle"
                :info-block="infoBlock"
                :cards="cards"
                :has-draft="true"
                :actual="getACTUAL_DOCUMENT"
                :show-button="!haveACTUAL_KI"
                @download="download"
                @download-scan="downloadScan"
                @fillOut="$router.push('/cabinet/documents/forms/ki')"
        />
    </div>
</template>

<script>
    // TODO return this to k-i page upper for return a button
    // :show-button="!haveACTUAL_KI"
    import KI from "../../../components/pages/cabinet/documents/KIType";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DocumentsKI",
        components: {KI},
        watch: {
            docChanged() {
                if (this.changedDocAlias === "appl-ci"){
                    this.GET_DOCUMENTS("appl-ci")
                    this.GET_ALL_DOCUMENTS({ documentsListType: "actuals" });
                }
            }
        },
        computed: {
            ...mapGetters("DocumentsModule", ["getDOCUMENTS", "getDRAFT", "getACTUAL_DOCUMENT", "haveACTUAL_KI"]),
            ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias"]),
            hasDraft() {
                return !!this.getDRAFT
            },
            cards() {
                return this.getDOCUMENTS ? this.getDOCUMENTS.map(el => {
                    let element = {...el}
                    element.title = "Заявление на признание физического лица  квалифицированным инвестором"
                    element.downloadLink = true
                    return element
                }) : null
            }
        },
        data() {
            return {
                documentKey: 0,
                pageTitle: "Квалификация",
                infoText: null,
                infoBlock: "<p>В соответствии с Федеральным законом от 22.04.1996 № 39-ФЗ «О рынке ценных бумаг» и Указанием Банка России от 29.04.2015 № 3629-У «О признании лиц квалифицированными инвесторами и порядке ведения реестра лиц, признанных квалифицированными инвесторами» квалицированный инвестор, физическое лицо, <b>должен соответствовать одному из перечисленных требований:</b></p>" +
                    "<ul>" +
                    "<li>владеть активами (ценные бумаги или денежные средства) на сумму <b>не менее 6 млн руб.</b>;</li>" +
                    "<li><b>обладать опытом работы в организации</b>, которая совершала сделки с ценными бумагами не менее 2-х лет, если такая организация является квалифицированным инвестором, или не менее трех лет в иных случаях;</li>" +
                    "<li><b>обладать опытом работы в должности</b>, при назначении (избрании) на которую в соответствии с федеральными законами требовалось согласование Банка России</li>" +
                    "<li><b>совершать сделки с ценными бумагами и (или) заключать договоры</b>, являющиеся производными финансовыми инструментами, со следующей регулярностью: не менее одной сделки в месяц и в среднем не менее десяти сделок в квартал в течение четырех кварталов (то есть года), объемом не менее 6 млн рублей;</li>" +
                    "<li><b>наличие высшего экономического образования</b> или любого из аттестатов: свидетельство о квалификации для работы на финансовом рынке, сертификат Chartered Financial Analyst (CFA), сертификат Certified International Investment Analyst (CIIA), сертификат Financial Risk Manager (FRM). При этом в первом случае вуз на момент выдачи диплома должен обладать правом аттестации граждан в сфере профессиональной деятельности на рынке ценных бумаг.</li>" +
                    "</ul>"
            }
        },

        methods: {
            ...mapActions("DocumentsModule", [
                "GET_DOCUMENTS",
                "DOWNLOAD_DOCUMENT",
                "GET_ALL_DOCUMENTS"
            ]),
            ...mapActions("regModule", [
                "DOWNLOAD_FILE"
            ]),
            download(link) {
                this.DOWNLOAD_DOCUMENT({id: link, name: "Признание физического лица квалифицированным инвестором.pdf"})
            },
            downloadScan(file) {
                this.DOWNLOAD_FILE(file)
            },
        },
        mounted() {
            this.GET_DOCUMENTS("appl-ci")
            this.GET_ALL_DOCUMENTS({ documentsListType: "actuals" });
        }
    }
</script>

<style scoped lang="scss">
</style>
